import utilActions from '@/api/actions';

const authorizePortcallberth = {
	id: 'authorizePortcallberth',
	selectionType: 'single',
	label: 'portcallberths.actions.authorizePortcallberth',
	functionality: 'AUTHORIZE_BERTH',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return (registries && registries[0].berthingstatusid === 1) || (registries && registries[0].berthingstatusid === 6);
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-authorizePortcallberth-' + model.name + '-show', row);
	}
};

const initPortcallberth = {
	id: 'authorizePinitPortcallberthortcallberth',
	selectionType: 'single',
	label: 'portcallberths.actions.initPortcallberth',
	functionality: 'INITIATE_BERTHS',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 2;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-initPortcallberth-' + model.name + '-show', row);
	}
};

const finishPortcallberth = {
	id: 'finishPortcallberth',
	selectionType: 'single',
	label: 'portcallberths.actions.finishPortcallberth',
	functionality: 'COMPLETE_BERTH',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 3;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-finishPortcallberth-' + model.name + '-show', row);
	}
};

const modifauthorizePB = {
	id: 'modifauthorizePB',
	selectionType: 'single',
	label: 'portcallberths.actions.modifauthorizePB',
	functionality: 'AUTHORIZE_BERTH',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 2;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-modifauthorizePB-' + model.name + '-show', row);
	}
};

const modifinitPB = {
	id: 'modifinitPB',
	selectionType: 'single',
	label: 'portcallberths.actions.modifinitPB',
	functionality: 'AUTHORIZE_BERTH',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 3;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-modifinitPB-' + model.name + '-show', row);
	}
};

const modifinishPB = {
	id: 'modifinitPB',
	selectionType: 'single',
	label: 'portcallberths.actions.modifinishPB',
	functionality: 'AUTHORIZE_BERTH',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 4;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-modifinishPB-' + model.name + '-show', row);
	}
};

const insertETC = {
	id: 'insertETC',
	selectionType: 'single',
	label: 'portcallberths.actions.insertETC',
	functionality: 'INSERT_ETC',
	checkAvailability: function (registries) {
		if (!canUserPerformAction(registries[0], this.$store)) {
			return false;
		}
		if (registries.length > 0) {
			return registries && registries[0].berthingstatusid === 3;
		}
		return false;
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];

		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-insertETC-' + model.name + '-show', row);
	}
};

function canUserPerformAction(row, store) {
	const userCodes = store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST;
	const relatedOrgs = store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS;
	const hasBeopandPodi = userCodes.includes('BEOP') && userCodes.includes('PODI');
	const berthoperatorid = `${row.berthoperatorid}`;
	const isRelatedOrg = relatedOrgs.some((orgId) => `${orgId}` === berthoperatorid);
	return !(hasBeopandPodi && !isRelatedOrg);
}

export default {
	gridactions: [authorizePortcallberth, initPortcallberth, finishPortcallberth, modifauthorizePB, modifinitPB, modifinishPB, insertETC],
	formactions: [authorizePortcallberth, initPortcallberth, finishPortcallberth, modifauthorizePB, modifinitPB, modifinishPB, insertETC]
};
