<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.authorizePortcallberth')"
			:modelName="modelName"
			:dialogName="authorizePortcallberth"
			:onOk="onOkauthorizePortcallberth"
			:onShow="onShowauthorizePortcallberth"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="3">
							<pui-date-field
								:id="`autheta-${modelName}`"
								:label="$t('portcallberths.autheta')"
								v-model="data.modalData.autheta"
								toplabel
								time
								required
								:min="new Date()"
								:max="data.modalData.authetd"
							></pui-date-field>
						</v-col>
						<v-col cols="3">
							<pui-date-field
								:id="`authetd-${modelName}`"
								:label="$t('portcallberths.authetd')"
								v-model="data.modalData.authetd"
								toplabel
								required
								time
								:min="data.modalData.autheta"
							></pui-date-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-select
								:id="`authberthingtypeid-${modelName}`"
								:attach="`authberthingtypeid-${modelName}`"
								:label="$t('portcallberths.authberthingtypeid')"
								toplabel
								clearable
								required
								v-model="data.modalData"
								modelName="mberthingtypes"
								:itemsToSelect="[{ berthingtypeid: data.modalData.authberthingtypeid }]"
								:modelFormMapping="{ berthingtypeid: 'authberthingtypeid' }"
								itemValue="berthingtypeid"
								itemText="typedesc"
								:order="{ typedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`authpurposecode-${modelName}`"
								:attach="`authpurposecode-${modelName}`"
								:label="$t('portcallberths.authpurposecode')"
								toplabel
								required
								clearable
								v-model="data.modalData"
								modelName="mportcallpurposes"
								:itemsToSelect="[{ portcallpurposecode: data.modalData.reqpurposeid }]"
								:modelFormMapping="{ portcallpurposecode: 'authpurposecode' }"
								itemValue="portcallpurposecode"
								itemText="purposedesc"
								:order="{ purposedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="2">
							<pui-number-field
								:id="`authnorayfrom-${modelName}`"
								v-model="data.modalData.authnorayfrom"
								:label="$t('portcallberths.authnorayfrom')"
								toplabel
								required
								:max="data.modalData.authnorayto"
							></pui-number-field>
						</v-col>
						<v-col cols="2">
							<pui-number-field
								:id="`authnorayto-${modelName}`"
								v-model="data.modalData.authnorayto"
								:label="$t('portcallberths.authnorayto')"
								toplabel
								required
								:min="data.modalData.authnorayfrom"
							></pui-number-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.initPortcallberth')"
			:modelName="modelName"
			:dialogName="initPortcallberth"
			:onOk="onOkinitPortcallberth"
			:onShow="onShowinitPortcallberth"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
							<pui-date-field
								:id="`ata-${modelName}`"
								:label="$t('portcallberths.ata')"
								v-model="data.modalData.ata"
								toplabel
								time
								required
								:max="new Date()"
							></pui-date-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
							<pui-select
								:id="`actberthingtypeid-${modelName}`"
								:attach="`actberthingtypeid-${modelName}`"
								:label="$t('portcallberths.actberthingtypeid')"
								toplabel
								clearable
								v-model="data.modalData"
								modelName="mberthingtypes"
								:itemsToSelect="[{ berthingtypeid: data.modalData.actberthingtypeid }]"
								:modelFormMapping="{ berthingtypeid: 'actberthingtypeid' }"
								itemValue="berthingtypeid"
								itemText="typedesc"
								:order="{ typedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
							<pui-select
								:id="`actpurposecode-${modelName}`"
								:attach="`actpurposecode-${modelName}`"
								:label="$t('portcallberths.actpurposecode')"
								toplabel
								required
								clearable
								v-model="data.modalData"
								modelName="mportcallpurposes"
								:itemsToSelect="[{ portcallpurposecode: data.modalData.authpurposeid }]"
								:modelFormMapping="{ portcallpurposecode: 'actpurposecode' }"
								itemValue="portcallpurposecode"
								itemText="purposedesc"
								:order="{ purposedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
							<pui-number-field
								:id="`actnorayfrom-${modelName}`"
								v-model="data.modalData.actnorayfrom"
								:label="$t('portcallberths.actnorayfrom')"
								toplabel
								required
								:max="data.modalData.actnorayto"
							></pui-number-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
							<pui-number-field
								:id="`actnorayto-${modelName}`"
								v-model="data.modalData.actnorayto"
								:label="$t('portcallberths.actnorayto')"
								toplabel
								required
								:min="data.modalData.actnorayfrom"
							></pui-number-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.finishPortcallberth')"
			:modelName="modelName"
			:dialogName="finishPortcallberth"
			:onOk="onOkfinishPortcallberth"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="12">
							<pui-date-field
								:id="`ata-${modelName}`"
								:label="$t('portcallberths.atd')"
								v-model="data.modalData.atd"
								toplabel
								time
								required
								:min="data.modalData.ata"
								:max="new Date()"
							></pui-date-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.modifauthorizePB')"
			:modelName="modelName"
			:dialogName="modifauthorizePB"
			:onOk="onOkmodifauthorizePB"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="3">
							<pui-date-field
								:id="`autheta-${modelName}`"
								:label="$t('portcallberths.autheta')"
								v-model="data.modalData.autheta"
								toplabel
								time
								required
								:min="new Date()"
								:max="data.modalData.authetd"
							></pui-date-field>
						</v-col>
						<v-col cols="3">
							<pui-date-field
								:id="`authetd-${modelName}`"
								:label="$t('portcallberths.authetd')"
								v-model="data.modalData.authetd"
								toplabel
								required
								time
								:min="data.modalData.autheta"
							></pui-date-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-select
								:id="`authberthingtypeid-${modelName}`"
								:attach="`authberthingtypeid-${modelName}`"
								:label="$t('portcallberths.authberthingtypeid')"
								toplabel
								clearable
								required
								v-model="data.modalData"
								modelName="mberthingtypes"
								:itemsToSelect="[{ berthingtypeid: data.modalData.authberthingtypeid }]"
								:modelFormMapping="{ berthingtypeid: 'authberthingtypeid' }"
								itemValue="berthingtypeid"
								itemText="typedesc"
								:order="{ typedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>

						<v-col cols="3">
							<pui-select
								:id="`authpurposecode-${modelName}`"
								:attach="`authpurposecode-${modelName}`"
								:label="$t('portcallberths.authpurposecode')"
								toplabel
								required
								clearable
								v-model="data.modalData"
								modelName="mportcallpurposes"
								:itemsToSelect="[{ portcallpurposecode: data.modalData.authpurposeid }]"
								:modelFormMapping="{ portcallpurposecode: 'authpurposecode' }"
								itemValue="portcallpurposecode"
								itemText="purposedesc"
								:order="{ purposedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>

						<v-col cols="2">
							<pui-number-field
								:id="`authnorayfrom-${modelName}`"
								v-model="data.modalData.authnorayfrom"
								:label="$t('portcallberths.authnorayfrom')"
								toplabel
								required
								:max="data.modalData.authnorayto"
							></pui-number-field>
						</v-col>
						<v-col cols="2">
							<pui-number-field
								:id="`authnorayto-${modelName}`"
								v-model="data.modalData.authnorayto"
								:label="$t('portcallberths.authnorayto')"
								toplabel
								required
								:min="data.modalData.authnorayfrom"
							></pui-number-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.modifinitPB')"
			:modelName="modelName"
			:dialogName="modifinitPB"
			:onOk="onOkmodifinitPB"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="3">
							<pui-date-field
								:id="`ata-${modelName}`"
								:label="$t('portcallberths.ata')"
								v-model="data.modalData.ata"
								toplabel
								time
								required
								:max="new Date()"
							></pui-date-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-select
								:id="`authberthingtypeid-${modelName}`"
								:attach="`authberthingtypeid-${modelName}`"
								:label="$t('portcallberths.authberthingtypeid')"
								toplabel
								clearable
								required
								v-model="data.modalData"
								modelName="mberthingtypes"
								:itemsToSelect="[{ berthingtypeid: data.modalData.actberthingtypeid }]"
								:modelFormMapping="{ berthingtypeid: 'actberthingtypeid' }"
								itemValue="berthingtypeid"
								itemText="typedesc"
								:order="{ typedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
							<pui-select
								:id="`actpurposecode-${modelName}`"
								:attach="`actpurposecode-${modelName}`"
								:label="$t('portcallberths.actpurposecode')"
								toplabel
								required
								clearable
								v-model="data.modalData"
								modelName="mportcallpurposes"
								:itemsToSelect="[{ portcallpurposecode: data.modalData.actpurposeid }]"
								:modelFormMapping="{ portcallpurposecode: 'actpurposecode' }"
								itemValue="portcallpurposecode"
								itemText="purposedesc"
								:order="{ purposedesc: 'asc' }"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="2">
							<pui-number-field
								:id="`actnorayfrom-${modelName}`"
								v-model="data.modalData.actnorayfrom"
								:label="$t('portcallberths.actnorayfrom')"
								toplabel
								required
								:max="data.modalData.actnorayto"
							></pui-number-field>
						</v-col>
						<v-col cols="2">
							<pui-number-field
								:id="`actnorayto-${modelName}`"
								v-model="data.modalData.actnorayto"
								:label="$t('portcallberths.actnorayto')"
								toplabel
								required
								:min="data.modalData.actnorayfrom"
							></pui-number-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.modifinishPB')"
			:modelName="modelName"
			:dialogName="modifinishPB"
			:onOk="onOkmodifinishPB"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="12">
							<pui-date-field
								:id="`ata-${modelName}`"
								:label="$t('portcallberths.atd')"
								v-model="data.modalData.atd"
								toplabel
								time
								required
								:min="data.modalData.ata"
								:max="new Date()"
							></pui-date-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('portcallberths.actions.insertETC')"
			:modelName="modelName"
			:dialogName="insertETC"
			:onOk="onOkinsertETC"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<div>
					<v-row class="mt-4">
						<v-col cols="12">
							<pui-date-field
								:id="`ata-${modelName}`"
								:label="$t('portcallberths.etc')"
								v-model="data.modalData.etc"
								toplabel
								time
								required
								:min="new Date()"
							></pui-date-field>
						</v-col>
					</v-row>
				</div>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'portcallberths-modals',
	data() {
		return {
			authorizePortcallberth: 'authorizePortcallberth',
			initPortcallberth: 'initPortcallberth',
			finishPortcallberth: 'finishPortcallberth',
			modifauthorizePB: 'modifauthorizePB',
			modifinitPB: 'modifinitPB',
			modifinishPB: 'modifinishPB',
			insertETC: 'insertETC'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},

	methods: {
		filterDocks(modalData) {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'locode', op: 'eq', data: modalData.locode }]
					}
				]
			};
		},
		onShowauthorizePortcallberth(modalData) {
			modalData.autheta = modalData.reqeta;
			modalData.authetd = modalData.reqetd;
			modalData.authpurposecode = modalData.reqpurposecode;
			modalData.authdockid = modalData.reqdockid;
			modalData.authnorayfrom = modalData.reqnorayfrom;
			modalData.authnorayto = modalData.reqnorayto;
			modalData.authberthingtypeid = modalData.reqberthingtypeid;
		},
		onOkauthorizePortcallberth(modalData) {
			const url = '/portcallberths/authPortcallberth';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.authorizePortcallberth');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				autheta: modalData.autheta,
				authetd: modalData.authetd,
				authpurposecode: modalData.authpurposecode,
				authdockid: modalData.authdockid,
				authnorayfrom: modalData.authnorayfrom,
				authnorayto: modalData.authnorayto,
				authberthingtypeid: modalData.authberthingtypeid,
				berthingstatusid: 2,
				authdate: new Date()
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
						localStorage.setItem('tabFromAction', 'portcallberths');
						this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onShowinitPortcallberth(modalData) {
			modalData.actpurposecode = modalData.authpurposecode;
			modalData.actdockid = modalData.authdockid;
			modalData.actnorayfrom = modalData.authnorayfrom;
			modalData.actnorayto = modalData.authnorayto;
			modalData.actberthingtypeid = modalData.authberthingtypeid;
		},
		onOkinitPortcallberth(modalData) {
			const url = '/portcallberths/initPortcallberth';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.initPortcallberth');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				ata: modalData.ata,
				actpurposecode: modalData.actpurposecode,
				actdockid: modalData.actdockid,
				actnorayfrom: modalData.actnorayfrom,
				actnorayto: modalData.actnorayto,
				actberthingtypeid: modalData.actberthingtypeid,
				berthingstatusid: 3
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
						localStorage.setItem('tabFromAction', 'portcallberths');
						this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkfinishPortcallberth(modalData) {
			const url = '/portcallberths/finishPortcallberth';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.initPortcallberth');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				atd: modalData.atd,
				berthingstatusid: 4
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
						localStorage.setItem('tabFromAction', 'portcallberths');
						this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkmodifauthorizePB(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.patch;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.modifauthorizePB');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				autheta: modalData.autheta,
				authetd: modalData.authetd,
				authpurposecode: modalData.authpurposecode,
				authdockid: modalData.authdockid,
				authnorayfrom: modalData.authnorayfrom,
				authnorayto: modalData.authnorayto,
				authberthingtypeid: modalData.authberthingtypeid
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
						localStorage.setItem('tabFromAction', 'portcallberths');
						this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkmodifinitPB(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.patch;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.modifauthorizePB');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				ata: modalData.ata,
				actpurposecode: modalData.actpurposecode,
				actdockid: modalData.actdockid,
				actnorayfrom: modalData.actnorayfrom,
				actnorayto: modalData.actnorayto,
				actberthingtypeid: modalData.actberthingtypeid
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(message, title);

						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkmodifinishPB(modalData) {
			const url = this.$store.getters.getModelByName(this.modelName).url.insertATD;
			console.log(url);
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.modifauthorizePB');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};
			const patchData = {
				atd: modalData.atd
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						this.$puiNotify.success(message, title);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
						localStorage.setItem('tabFromAction', 'portcallberths');
						this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		onOkinsertETC(modalData) {
			const url = '/portcallberths/insertETC';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('portcallberths.actions.insertETC');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			const pk = {
				portcallberthid: modalData.portcallberthid
			};

			const patchData = {
				etc: modalData.etc
			};

			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(message, title);

						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		}
	}
};
</script>
